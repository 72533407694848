<template>
  <div class="row align-items-center justify-content-center text-center">
    <LoginAgentFallbackDialog
      :visible="isAgentFallbackDialogVisibleRef"
      @close="isAgentFallbackDialogVisibleRef = false"
    />
    <ElForm
      ref="form"
      :model="payload"
      :rules="rulesRef"
      class="col-12 text-center pt-0"
      @submit.native.prevent="submit"
    >
      <h1 class="mb-4 font-32">
        Sign In
      </h1>
      <SdFloatLabel for-input="email">
        <ElFormItem
          prop="email"
          class="mb-3"
        >
          <ElInput
            id="email"
            v-model="payload.email"
            placeholder="Email"
          />
        </ElFormItem>
      </SdFloatLabel>
      <SdFloatLabel for-input="password">
        <ElFormItem
          prop="password"
          class="mb-1"
        >
          <ElInput
            id="password"
            v-model="payload.password"
            :type="isPasswordVisibleRef ? 'text' : 'password'"
            placeholder="Password"
          >
            <template slot="suffix">
              <i
                v-show="payload.password.length"
                class="pointer px-1"
                :class="isPasswordVisibleRef ? 'sdicon-eye-slash' : 'sdicon-eye'"
                @click="triggerPasswordVisibility"
              />
            </template>
          </ElInput>
        </ElFormItem>
      </SdFloatLabel>
      <div class="sign-in_forgot-password-wrapper font-13 font-weight-bold text-right">
        <router-link
          :to="{ name: 'auth.forgot-password' }"
          class="strong"
        >
          Forgot password?
        </router-link>
      </div>
      <ElButton
        :loading="formState.loading"
        type="primary"
        class="w-100 mt-4"
        native-type="submit"
      >
        Sign In
      </ElButton>
      <div class="mt-4">
        <span class="text-gray-dark">Don't have an account? </span>
        <a href="https://www.showdigs.com/pricing">
          See how to get started!
        </a>
      </div>
    </ElForm>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import get from 'lodash.get';

import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import LoginAgentFallbackDialog from '../../components/auth/LoginAgentFallbackDialog';

export default {
  components: { LoginAgentFallbackDialog },
  setup(props, context) {
    const { $store, $router, $route } = context.root;
    const payload = reactive({
      email: '',
      password: '',
    });
    const rulesRef = ref(formRules);
    const isPasswordVisibleRef = ref(false);
    const isAgentFallbackDialogVisibleRef = ref(false);
    const formState = reactive(defaultFormState);
    showAlertIfHasRouteParams();

    $store.dispatch('Auth/logout'); // Temp, only for the new login mechanism migration
    return {
      payload,
      formState,
      rulesRef,
      isPasswordVisibleRef,
      isAgentFallbackDialogVisibleRef,
      triggerPasswordVisibility,
      submit,
    };

    function showAlertIfHasRouteParams() {
      if ($route.query.verified) {
        showSuccessNotify(context, "You've already been verified. Sign in to start using Showdigs.");
      } else if ($route.query.expired_invitation) {
        showErrorNotify(context, 'Sorry, but your invitation expired, ask the inviter to resend you an invitation.');
      } else if ($route.query.invalid_signature) {
        showErrorNotify(context, 'Invalid verification signature');
      }
    }

    function triggerPasswordVisibility() {
      return (isPasswordVisibleRef.value = !isPasswordVisibleRef.value);
    }

    async function submit() {
      formState.loading = true;
      try {
        payload.email = payload.email.trim();
        await context.refs.form.validate();
        const loginResponse = await $store.dispatch('Auth/login', payload);
        if (loginResponse === 'NOT_A_PM_USER') {
          isAgentFallbackDialogVisibleRef.value = true;
          await $store.dispatch('Auth/logout', payload);
        } else {
          $router.replace({
            name: $store.getters['Auth/lastSubscription'] ? 'app.properties' : 'app.subscribe',
          });
        }
        formState.loading = false;
      } catch (error) {
        formState.loading = false;
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        if (get(error, 'response.data.user.verified') === false) {
          $store.commit('Auth/setUser', error.response.data.user);
          $router.push({ name: 'auth.register' });
        }
        showErrorNotify(context, error.message, { duration: 0 });
      }
    }
  },
};
const defaultFormState = {
  loading: false,
};
const formRules = {
  email: [
    {
      required: true,
      type: 'email',
      message: 'Email is required',
    },
  ],
  password: [
    {
      required: true,
      message: 'Password is required',
    },
  ],
};
</script>

<style lang="scss">
.sign-in {
  .el-button {
    font-weight: $font-weight-bold;
  }

  .el-input--suffix .el-input__inner {
    padding-right: 40px;
  }
}
</style>
