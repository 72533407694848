<template>
  <ElDialog
    :center="true"
    top="30vh"
    :show-close="false"
    :visible="visible"
    :width="$viewport.lt.md ? 'auto': '400px'"
    :fullscreen="$viewport.lt.md"
    @close="$emit('close')"
  >
    <div slot="title">
      <h2 class="mb-1">
        Hi Showdigs Agent!
      </h2>
    </div>
    <div class="row justify-content-center align-items-center mt-1 mb-3 no-gutters">
      To log in please use our agent mobile app:
    </div>
    <div class="row justify-center">
      <a
        class="col-xs-6 col-md-6 text-center"
        :href="iosUrl"
        target="_blank"
      >
        <img
          src="@/assets/app-store-badge.svg"
          alt="Get it on App Store"
          class="mobile-app-badge"
        >
      </a>
      <a
        class="col-xs-6 col-md-6 text-center"
        :href="androidUrl"
        target="_blank"
      >
        <img
          src="@/assets/google-play-badge.png"
          alt="Get it on Google Play"
          class="mobile-app-badge"
        >
      </a>
    </div>
    <span
      slot="footer"
      class="row justify-content-center"
    >
      <div class="col-4">
        <ElButton
          class="w-100"
          size="medium"
          @click="$emit('close')"
        >
          Ok
        </ElButton>
      </div>
    </span>
  </ElDialog>
</template>

<script>
const androidUrl = 'https://play.google.com/store/apps/details?id=com.showdigs.app';
const iosUrl = 'https://itunes.apple.com/us/app/apple-store/id1438418662?ls=1&mt=8';
export default {
  name: 'LoginAgentFallbackDialog',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    return { androidUrl, iosUrl };
  },
};
</script>

<style lang="scss" scoped>
    .mobile-app-badge {
        width: 150px;
        height: 50px;
    }
</style>
